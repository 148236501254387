import axios from "axios";

import { Constants } from "./../common/constant";

export default class Digilocker {
  async GetToken(formvalues) {
    try {
      const response = await axios.post(
        Constants.URL + "digilocker/token",
        JSON.stringify(formvalues),
        { headers: { "Content-type": "application/json" } }
      );
      return response;
    } catch (error) {
      const api_response_1 = { success: false };
      return api_response_1;
    }
  }

  async GetIssuedDocument(formvalues) {
    const data = { token: formvalues };

    try {
      const response = await axios.post(
        Constants.URL + "digilocker/issuedDocument",
        JSON.stringify(data),
        { headers: { "Content-type": "application/json" } }
      );
      return response;
    } catch (error) {
      const api_response_1 = { success: false };
      return api_response_1;
    }
  }

  async GetDocumentData(uri, token) {
    const formvalues = { uri: uri, token: token };
    try {
      const response = await axios.post(
        Constants.URL + "digilocker/documentData",
        JSON.stringify(formvalues),
        { headers: { "Content-type": "application/json" } }
      );
      return response;
    } catch (error) {
      const api_response_1 = { success: false };
      return api_response_1;
    }
  }

  async GetDocumentFile(uri, token) {
    const formvalues = { uri: uri, token: token };
    try {
      const response = await axios.post(
        Constants.URL + "digilocker/documentFile",
        JSON.stringify(formvalues),
        { headers: { "Content-type": "application/json" } }
      );

      return response;
    } catch (error) {
      const api_response_1 = { success: false };
      return api_response_1;
    }
  }
  async GetAadhaarData(token) {
    const formvalues = { token: token };
    try {
      const response = await axios.post(
        Constants.URL + "digilocker/aadhaarData",
        JSON.stringify(formvalues),
        { headers: { "Content-type": "application/json" } }
      );
      return response;
    } catch (error) {
      const api_response_1 = { success: false };
      return api_response_1;
    }
  }

  async AuthenticateUser(formvalues) {
    try {
      const response = await axios.post(
        Constants.URL + "subscribers/Authenticate",
        JSON.stringify(formvalues),
        { headers: { "Content-type": "application/json" } }
      );

      return response;
    } catch (error) {
      const api_response_1 = { success: false };
      return api_response_1;
    }
  }

  async UpdateData(formvalues) {
    try {
      const response = await axios.post(
        Constants.URL + "subscribers/UpdateData",
        JSON.stringify(formvalues),
        { headers: { "Content-type": "application/json" } }
      );

      return response;
    } catch (error) {
      const api_response_1 = { success: false };
      return api_response_1;
    }
  }

  async PullData(formvalues) {
    try {
      const response = await axios.post(
        Constants.URL + "digilocker/pullData",
        JSON.stringify(formvalues),
        { headers: { "Content-type": "application/json" } }
      );
      return response;
    } catch (error) {
      const api_response_1 = { success: false };
      return api_response_1;
    }
  }
}
