import React, { useState, useEffect } from "react";
import Digilocker from "../services/digilocker";
import { useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";

const Request = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const digilocker = new Digilocker();
  const referenceId = searchParams.get("referenceId");
  const clientId = searchParams.get("clientId");
  localStorage.setItem("referenceId", referenceId);
  localStorage.setItem("clientId", clientId);
  const data = { referenceId };
  useEffect(() => {
    digilocker
      .AuthenticateUser(data)
      .then((resultObj) => {
        if (resultObj.status === 200) {
          //handleClick();
        } else {
          //window.close();
          //TODO later if not authenticated or not valid token or KEY or subscriber
          console.log(JSON.stringify(resultObj));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleClick = () => {
    let clientid = '06F1D4F3'
	let responseURL = "https://digilocker.ui.itmines.in/Response"
	let url = `https://api.digitallocker.gov.in/public/oauth2/1/authorize?client_id=${clientid}&redirect_uri=${responseURL}&state=${generateRandomNumber()}&response_type=code&req_doctype=ADHAR,PANCR`;
    window.location.href = url;
  };

  const generateRandomNumber = () => {
    return Math.floor(1000 + Math.random() * 9000);
  };

  return (
    <div>
      <Grid container spacing={2}>
          <Grid item xs={12} md={12} className="head-instr">
            <h5>Instructions</h5>
            <p>In order to complete your KYC, kindly ensure that you provide consent to pull your PAN data. Also, in case if you are fetching your PAN data through Digilocker for the first time, kindly provide proper PAN Number and Name As Per PAN in order to complete your KYC</p>
          </Grid>
          <Grid item xs={12} md={12} align="center">
            <button onClick={handleClick}
              className={"btn btn-1 hover-filled-slide-right ShowFields"}>
                Proceed & Give Consent
              </button>
          </Grid>
      </Grid>      
    </div>
  );
};

export default Request;
