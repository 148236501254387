import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./view/signin";
import Request from "./view/request";
import Response from "./view/response";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Request" element={<Request />} />
          <Route path="/Response" element={<Response />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
