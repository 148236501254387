import { createTheme } from "@mui/material";
import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Montserrat:400,700', 'sans-serif'],
  }
});

// Create a theme instance.
export const style = createTheme({
 main: {overflow: 'auto'},
 palette: {
   primary: {
     main: '#3a67e1',
   },
   secondary: {
     main: '#00c7ba',
   },
 },
 
 typography: {
   fontFamily: "Open Sans, sans-serif",
   subtitle1: {
     fontFamily: "Open Sans, sans-serif",
     fontSize: '14px',
     color: '#ef4c5d',
     fontWeight: 600,
     textAlign: 'right',
   },
   subtitle2: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'right',
  },
 },
 shape: {
   borderRadius: 5
 },
 spacing: 5,

 // custom css
  mainheading: {
    fontFamily: "Lobster, cursive",
    fontSize: '28px',
    color: '#ef4c5d',
    lineHeight: 2,
    paddingLeft: '25px',
  },
  paperContainer: {
    backgroundColor: '#f0f8ff',
    backgroundSize: "cover",
    height: "100vh",
  },
  alertmsg: {
    margin: '15px 0 0 10px',
    fontFamily: "Open Sans, sans-serif",
    fontSize: '16px',
    fontWeight: 600,
  },
  cardBox: {
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 'rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px',
  },
  leftBox: {
    padding: '0px',
    // backgroundImage: 'linear-gradient(to left bottom, #0db0ab, #0094b0, #0076ae, #00559f, #302f7f)',
    backgroundColor: '#3a67e1',
  },
  leftText: {
    fontFamily: "Lobster, cursive",
    fontSize: '28px',
    color: '#ffffff',
    lineHeight: 1.5,
    paddingLeft: '10px',
    position: 'absolute',
    left: '22%',
    top: '50%',
    transform: 'translate(-50%, -50%)', 
  },
  otpfield: {width: '95%',},
  errormsg: {
    color: '#eb0000',
    fontFamily: "Open Sans, sans-serif",
    fontSize: '15px',
    marginTop: '5px',
  },
  // signup
  button: {
    color: '#fff',
    fontWeight: 600,
    backgroundColor: '#3a67e1',
    textTransform: 'none',
    fontSize: '17px',
    height: '40px',
    border: '0',
    borderRadius: '4px',
    width: '100%',
    cursor: 'pointer',
  },
  leftButtonLink: {
    fontFamily: "Open Sans, sans-serif",
    border: '2px solid #fff',
    marginLeft: '90px',
    color: '#fff',
    fontSize: '16px',
    padding: '8px 30px',
    borderRadius: '4px',
  },   
  // signin
  leftButtonLink2: {
    fontFamily: "Open Sans, sans-serif",
    border: '2px solid #fff',
    marginLeft: '35px',
    color: '#fff',
    fontSize: '16px',
    padding: '8px 30px',
    borderRadius: '4px',
  },
  fplink: {
    color: '#ef365c',
  },
  fpdiv: {
    textAlign: 'right',
    lineHeight: '1',
    fontWeight: '400',
    fontSize: '15px',
    color: '#ef365c',
    margin: '0',
    fontFamily: "Open Sans, sans-serif",
  },
  supdiv: {
    textAlign: 'center',
    lineHeight: '1',
    fontWeight: '600',
    fontSize: '15px',
    color: '#0eb0ac',
    margin: '0',
    fontFamily: "Open Sans, sans-serif",
  },
  otpdiv: {
    textAlign: 'center',
    lineHeight: '1',
    fontWeight: '600',
    fontSize: '15px',
    color: '#EF365C',
    margin: '0',
    fontFamily: "Open Sans, sans-serif",
  },
  erroricon: {
    fontSize: '24px',
    margin: '8px 15px 0px 0px',
    color: '#eb0000',
    float: 'right',
  },
  successicon: {
    fontSize: '24px',
    margin: '8px 15px 0px 0px',
    color: '#0dab03',
    float: 'right',
  },

  //All Forms
  cardAllBox: {
    boxShadow: 'rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px',
  },
  card: {
    padding: "20px",
  },
  paper: {
    //backgroundColor: '#f5f6fa',
    backgroundSize: "cover",
    minHeight: "94vh",
    margin: "20px",
  },
  lbGrid: {
    padding: '0 25px 20px',
  },
  field: {
    boxShadow: 'rgb(0 0 0 / 5%) 0px 2px 0px -2px, rgb(0 0 0 / 8%) 0px 1px 2px 0px, rgb(0 0 0 / 5%) 0px 1px 3px 0px',
    width: '100%',
    border: '0',
    borderRadius: '4px',
    margin: '0px 0 5px',
  },
  buttonall: {
    color: '#fff',
    fontWeight: 600,
    // backgroundColor: '#0eb0ac',
    textTransform: 'none',
    fontSize: '17px',
    height: '40px',
    border: '0',
    borderRadius: '30px',
    width: '26%',
    cursor: 'pointer',
    marginTop: '25px',
    backgroundImage: 'linear-gradient(to left, #f33f26, #f55223, #f76221, #f97121, #fa7f22)',
    border: '1px solid #ededed',
    boxShadow: 'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px'
  },
  breadcrumbs: {
    float: 'right',
  },
  imgPreview: {
    border: '1px solid rgb(0 0 0 / 26%)',
    width: '60px',
    height: '52px',
    borderRadius: '4px',
    marginLeft: '-15px',
    boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px',
  },
  previewDiv: {
    float: 'left',
    display: 'flex',
    padding: '20px 30px 0',
  },
  checkLabel: {
    fontSize: '18px',
    fontWeight: '700',
  },
  checkInput: {
    height: '20px',
    width: '50px',
  },
  documentTop: {
    //background: 'rgb(233 233 233)',
    padding: '9px 15px 7px',
    borderRadius: '4px',
    margin: '20px 20px 0',
    boxShadow: 'rgb(50 51 51 / 22%) 0px 0px 5px 3px',
  },
  uploadLabel: {
    fontSize: '17px',
    fontWeight: '700',
    paddingBottom: '10px',
  },
  errormsg: {
    marginTop: '10px',
    color: '#d32f2f',
    fontSize: '13px',
    marginLeft: '15px',
    fontFamily: "Open Sans, sans-serif",
  },
  pricingTop: {
    //background: 'rgb(233 233 233)',
    padding: '9px 15px 7px',
    borderRadius: '4px',
    margin: '0px 0px 0px',
    boxShadow: 'rgb(50 51 51 / 22%) 0px 0px 5px 3px',
    width: '100%',
  }
});










