import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Digilocker from "../services/digilocker";
//import Loader from '../component/Loader';
import { Formik, Form } from "formik";
import TextField from "../component/TextField";
import { Container, Grid } from "@mui/material";
import * as Yup from "yup";
import { style } from "../assets/css/style";

const Response = () => {
  const [pan, setPan] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    transactionID: localStorage.getItem("referenceId"),
    clientID: localStorage.getItem("clientId"),
    panUrl: "",
    token: "",
    refreshToken: "",
  });
  const search = useLocation().search;
  const digilocker = new Digilocker();
  var details = { ...data };
  const initialValues = { panNo: "", panName: "" };
  const [showFields, setShowFields] = useState(true);

  const validationSchema = Yup.object().shape({
    panNo: Yup.string().required("Pan No is required"),
    panName: Yup.string().required("Name as on Pan is required"),
  });

  useEffect(() => {
    const data = {
      code: new URLSearchParams(search).get("code"),
    };
    //console.log("useEffect data " + data);
    window.localStorage.setItem("ErrorOrNoConsent", "0");
    GetToken(data);
  }, []);

  const GetToken = (data) => {
    digilocker
      .GetToken(data)
      .then((resultObj) => {
        //console.log("GetToken resultObj " + resultObj);
        if (resultObj.status === 200) {
          details.token = resultObj.data.access_token;
          details.refreshToken = resultObj.data.refresh_token;
          setData(details);
          GetIssuedDocument(details.token);
        } else {
          window.close();
        }
      })
      .catch((error) => window.close());
  };

  const GetIssuedDocument = (token) => {
    digilocker
      .GetIssuedDocument(token)
      .then((resultObj) => {        
        if (resultObj.status === 200) {          
          var pan = resultObj.data.items.filter(
            (item) => item.doctype === "PANCR"
          );
          //pan = []
          if (pan.length > 0) {
            details.panUrl = pan[0].uri;
            //GetDocumentFile(details.panUrl,details.token);
            GetDocumentData(details.panUrl, details.token);
          } else {
            setLoading(false);
          }
          //GetDocumentFile(uri,token);
          //GetAadhaarData(token,panURL)
        }
      })
      .catch((error) => console.log(error));
  };

  const GetDocumentData = (uri, token) => {
    digilocker
      .GetDocumentData(uri, token)
      .then((resultObj) => {
        if (resultObj.status === 200) {
          GetAadhaarData(token, resultObj.data, uri);
        }
      })
      .catch((error) => window.close());
  };

  // const GetDocumentFile = (uri,token) =>
  // {
  // 	digilocker.GetDocumentFile(uri,token).then((resultObj) => {
  // 		if (resultObj.status === 200) {
  // 			details.panFileData = resultObj.data;
  // 			setData(details);
  // 		}
  // 	}).catch((error) => {
  // 		console.log(error)
  // 	})

  // }

  const GetAadhaarData = (token, pandata, panURL) => {
    digilocker
      .GetAadhaarData(token)
      .then((resultObj) => {
        if (resultObj.status === 200) {
          UpdateData(resultObj.data, pandata, panURL);
        }
      })
      .catch((error) => console.log(error));
  };

  const UpdateData = (aadhaarData, panData, panUrl) => {
    const data = {
      transactionID: localStorage.getItem("referenceId"),
      clientID: localStorage.getItem("clientId"),
      panUrl: panUrl,
      refreshToken: details.token,
      aadhaarData,
      panData,
    };

    digilocker
      .UpdateData(data)
      .then((resultObj) => {
        
        if (resultObj.status === 200) {
          window.close();
        }
      })
      .catch((error) => window.close());
  };

  const submitForm = (formValues) => {
    
    const errorOrNoConsent = window.localStorage.getItem("ErrorOrNoConsent");

    if (errorOrNoConsent === "1") {
      window.localStorage.setItem("ErrorOrNoConsent", "0");
      
      window.location.href = "https://digilocker.ui.itmines.in/Request?referenceId=" + localStorage.getItem("referenceId") +"&clientId=" + localStorage.getItem("clientId");
    }else{
    var data = { ...formValues };
    data.token = details.token;
    digilocker
      .PullData(data)
      .then((resultObj) => {
                
        if (resultObj.status === 200) {
          GetDocumentData(resultObj.data.uri, details.token);
          //window.close();
        }else {//if (!resultObj.success){
          //Show hide fields
          setShowFields(false);
          window.localStorage.setItem("ErrorOrNoConsent", "1");          
        }
      })
      .catch((error) => {
        //console.log("An error occurred:", error);
        window.localStorage.setItem("ErrorOrNoConsent", "1");    
        //TODO uncomment after test
        window.close();
      });
    }
  };

  if (loading)
    return <div>Loading</div>; //app is not ready (fake request is in process)
  else {
    return (
      <div>
        {" "}
        <Container>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              submitForm(values);
            }}
          >
            {({ errors, status, touched, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}  className={showFields ? "ShowFields" : "HideFields"}>
                    <TextField name="panNo" label="PAN Number" />
                  </Grid>
                  <Grid item xs={12} md={4}  className={showFields ? "ShowFields" : "HideFields"}>
                    <TextField name="panName" label="Name as on PAN" />
                  </Grid>
                  <Grid item xs={12} md={4} className={showFields ? "HideFields" : "ShowFields"}>
                    <div className="head-instr">
                      <p>Oops. It seems you did not provide consent for PAN. We will need to redo your KYC.</p>
                      <p>
                      Note: In order to complete your KYC, kindly ensure that you provide consent to pull your PAN data. Also, in case if you are fetching your PAN data through Digilocker for the first time, kindly provide proper PAN Number and Name As Per PAN in order to complete your KYC
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} align="center">
                    <button
                      type="submit"
                      style={style.buttonall}
                      className={showFields ? "btn btn-1 hover-filled-slide-right ShowFields" : "btn btn-1 hover-filled-slide-right HideFields"}>
                      <span>Submit</span>
                    </button>
                    <button
                      type="submit"
                      style={style.buttonall}                      
                      className={showFields ? "btn btn-1 hover-filled-slide-right HideFields" : "btn btn-1 hover-filled-slide-right ShowFields"}>
                      <span>Recheck</span>
                    </button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    );
  }
};

export default Response;
